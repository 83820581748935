import {types} from './type';
import API from '../api';
import {message} from 'antd';
const {yardList, yardAdd, yardEdit} = API;

export const fetchYardList = () => dispatch => {
  dispatch ({type: types.SHOW_LOADING, loading: true});
  yardList ()
    .then (res => {
      if (res.data.success === true) {
        const data = res.data.data.map ((val, index) => {
          val.coordinates = val.coordinates.coordinates;
          return val;
        });

        dispatch ({
          type: types.YARD_LOCATION_LIST_DATA_FETCH,
          payload: data,
        });
      } else {
        message.error ({content: `${res.data.message}`, duration: 2});
      }
      dispatch ({type: types.SHOW_LOADING, loading: false});
    })
    .catch (err => {
      message.error ({content: `${err}`, duration: 2});
    });
};

export const addYardLocation = (data,callback) => dispatch => {
  dispatch ({type: types.SHOW_LOADING, loading: true});
  yardAdd (data)
    .then (res => {
      if (res.data.success === true) {
        callback(res.data.success)
        const newYard = res.data.data;

        newYard.coordinates = newYard.coordinates.coordinates;
        message.success ({content: `Yard Added`, duration: 2});
        dispatch ({
          type: types.YARD_LOCATION_ADD_DATA,
          newYardAdd: {
            id: parseInt (newYard.id),
            status: parseInt (newYard.status),
            name: data.name,
            address: data.address,
            coordinates: data.coordinates,
          },
        });
      } else {
        message.error ({content: `${res.data.message}`, duration: 2});
      }
      dispatch ({type: types.SHOW_LOADING, loading: false});
    })
    .catch (err => {
      message.error ({content: `${err}`, duration: 2});
    });
};

export const EditYardLocation = (data,callback) => dispatch => {
  dispatch ({type: types.SHOW_LOADING, loading: true});
  yardEdit (data)
    .then (res => {
      if (res.data.success === true) {
        callback(res.data.success)
        message.success ({content: `Yard Edit`, duration: 2});
        dispatch ({
          type: types.YARD_LOCATION_EDIT_DATA,
          editYardData: res.data.data,
          id: data.id,
        });
      } else {
        message.error ({content: `${res.data.message}`, duration: 2});
      }
      dispatch ({type: types.SHOW_LOADING, loading: false});
    })
    .catch (err => {
      message.error ({content: `${err}`, duration: 2});
    });
};
